import { customElement, property } from "lit/decorators.js";
import { html, css, LitElement } from "lit";
import Highcharts from 'highcharts/highcharts';
import STRINGS from '~/lang/strings_el.json';
import Utils from '~/utils.js';
import bootstrap from '~/modules/bootstrap/css/bootstrap.js';
import fontAwesome from '~/modules/fontawesome/css/fontawesome.js';
import style from '~/modules/css.js';
import * as XLSX from "xlsx";

@customElement("funds-bars")
export class FundsBars extends LitElement {
    static get properties() {
        return {
            classes: { type: Object },
            data: {type: Array, attribute: true, reflect: true },
            tableConf: {type: Array}
        }
    }

    static styles = [fontAwesome,bootstrap,style]
    constructor(){
        super();
        this.classes = { wrapper: true, switchedView: false };
        this.data=[];
        this.tableConf = [
            {property: 'Titlos', header: 'XX'},
            {property: 'Sinolo', header: STRINGS.CHARTS.FUNDS.AMOUNT_TOTAL}
        ]
    }
    firstUpdated(changedProperties) {
        this.fetchData();
    }

    fetchData() {
        fetch('https://2027.anaptyxi.gov.gr/GetData.ashx?chart=funds')
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.errMsg) {
                    throw new Error(STRINGS.ERRORS.NETWORK_ERROR);
                }else{
                    this.data = data;
                    this._enableChart1();
                    this._enableChart2();
                    this.initGrid();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
  
    render() {
        return Utils.getChartContainerSplit.call(this,this.data,STRINGS.CHARTS.FUNDS.COLS_TITLE);
    }  
    
    initGrid(){
        let grid = this.shadowRoot.querySelector("#grid");
        grid.options={
            data: this.data,
            info: false,
            paging: false,
            searching: false,
            columns: [
                {data: 'Titlos', title: STRINGS.CHARTS.FUNDS.XCOLUMN.toString().replace(" ","<br>")+"<br><br><br>",className: 'dt-head-center'},
                {data: 'Sinolo', width: Utils.amountColWidth, title: STRINGS.CHARTS.FUNDS.AMOUNT_TOTAL.toString().replace(" ","<br>")+"<br>€", className: 'dt-head-center dt-body-right',type: 'num-fmt', render: function (data, type, row) {
                    return type==="sort"?data:data.toLocaleString('el-GR');
                }},
                {data: 'Enosiaki', width: Utils.amountColWidth, title: STRINGS.CHARTS.FUNDS.AMOUNT_EU.toString().replace(" ","<br>")+"<br>€", className: 'dt-head-center dt-body-right',type: 'num-fmt', render: function (data, type, row) {
                    return type==="sort"?data:data.toLocaleString('el-GR');
                }},
                {data: 'Ethniki', width: Utils.amountColWidth, title: STRINGS.CHARTS.FUNDS.AMOUNT_NATIONAL.toString().replace(" ","<br>")+"<br>€", className: 'dt-head-center dt-body-right',type: 'num-fmt', render: function (data, type, row) {
                    return type==="sort"?data:data.toLocaleString('el-GR');
                }}
            ],
            order: [[1, 'desc']]
        };
    }
    
    _switchView(){
        this.classes.switchedView=!this.classes.switchedView;
        this.shadowRoot.querySelector(".wrapper").classList.toggle("switchedView");
    }

    _download(){
        let filename='Export.xlsx';
        let exportData = this.data.map(a=>{
            let item={};
            item[STRINGS.CHARTS.FUNDS.XCOLUMN]=a.Titlos;
            item[STRINGS.CHARTS.FUNDS.AMOUNT_TOTAL]=a.Sinolo;
            item[STRINGS.CHARTS.FUNDS.AMOUNT_EU]=a.Enosiaki;
            item[STRINGS.CHARTS.FUNDS.AMOUNT_NATIONAL]=a.Ethniki;
            return item;
        });
        var ws = XLSX.utils.json_to_sheet(exportData);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb,filename);
    }
    _enableChart1() {
        let sum = this.data.reduce((a,currentValue)=>{return a+currentValue.Sinolo;},0);
        let chartData1 = this.data.map((a)=>{
            return {
                name: a.Titlos.replace(" ","<br/>"),
                y: a.Sinolo,
                title: STRINGS.CHARTS.FUNDS.AMOUNT_TOTAL,
                color: Utils.getFundsColors(a["ID"]),
                sliced: false
            }
        });
        let chartData2 = this.data.map((a)=>{
            return {
                name: a.Titlos,
                y: a.Enosiaki,
                title: STRINGS.CHARTS.FUNDS.AMOUNT_EU,
                sliced: false
            }
        });
        let chartData3 = this.data.map((a)=>{
            return {
                name: a.Titlos,
                y: a.Ethniki,
                title: STRINGS.CHARTS.FUNDS.AMOUNT_NATIONAL,
                sliced: false
            }
        });
        chartData1=chartData1.sort((a,b)=>b.y-a.y); 
        chartData2=chartData2.sort((a,b)=>b.y-a.y); 
        chartData3=chartData3.sort((a,b)=>b.y-a.y); 
        let thechart = Highcharts.chart(
            this.shadowRoot.querySelector('#container1'), {
                chart: {
                    type: 'pie',
                },
                title: {
                    text: undefined
                },
                credits: {
                    enabled: false
                },
                xAxis: {
            
                },
                legend: {
                    enabled: true
                },  
                series: [
                    {
                        data: chartData1,
                        name: STRINGS.CHARTS.FUNDS.AMOUNT_TOTAL,
                        innerSize: '50%',
                        size: '75%',
                    }/*,
                    {
                        data: chartData2,
                        name: STRINGS.CHARTS.FUNDS.AMOUNT_EU,
                        size: '75%',
                        innerSize: '70%',
                        
                    },
                    {
                        data: chartData3,
                        name: STRINGS.CHARTS.FUNDS.AMOUNT_NATIONAL,
                        size: '45%',
                        
                    }*/
                ],
                plotOptions: {
                    series: {
                        colorByPoint: true,
                        dataLabels: {
                            formatter: function () {
                                let x = Utils.round(this.y*100/sum,2);
                                let html ="";
                                html+="<div style='text-align: center; 9px;white-space: normal;'>";
                                html+=this.point.name+':<br/> '+x+'%';
                                html+="</div>";
                                return html;
                            },
                            connectorShape: 'crookedLine',
                            alignTo: 'plotEdges',
                            useHTML: true,
                            crop: false
                        },
                        states: {
                            inactive: {
                                opacity: 1
                            }
                        },
                        tooltip: {
                            headerFormat: "<div class='hTooltipHeader'>{point.key}</div><br><br>",
                            pointFormatter: function() {
                                let x = Utils.round(this.y,2);
                                return "<div class=hTooltipBody'>"+this.title+': <b>'+this.y.toLocaleString('el-GR')+" €</b></div>";
                            },
                            useHTML: true
                        },
                        borderRadius: 5
                
                    }
                }
        });
        
      }

      _enableChart2() {
        let colors = Utils.getHighchartColors();
        let series=[
            {
                name: STRINGS.CHARTS.FUNDS.AMOUNT_TOTAL,
                data: [],
                color: Utils.getCohesionColors("AMOUNT_TOTAL"),
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
            },
            {
                name: STRINGS.CHARTS.FUNDS.AMOUNT_EU,
                data: [],
                color: Utils.getCohesionColors("AMOUNT_EU"),
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
            },
            {
                name: STRINGS.CHARTS.FUNDS.AMOUNT_NATIONAL,
                data: [],
                color: Utils.getCohesionColors("AMOUNT_NATIONAL"),
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
            }
        ];
        for (let i=0;i<this.data.length;i++){
            let a = this.data[i];
            series[0].data.push({name: a.Titlos, y: a.Sinolo/1000000000  });
            series[1].data.push({name: a.Titlos, y: a.Enosiaki/1000000000  });
            series[2].data.push({name: a.Titlos, y: a.Ethniki/1000000000  });
            series[0].data.sort((a,b)=>b.y-a.y);
            series[1].data.sort((a,b)=>b.y-a.y);
            series[2].data.sort((a,b)=>b.y-a.y);
        }
        Highcharts.chart(
            this.shadowRoot.querySelector('#container2'), {
                chart: {
                    type: 'bar'
                },
                title: {
                    text: undefined
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    type: 'category'
                },
                yAxis: {
                    title: {
                        text: STRINGS.CHARTS.FUNDS.COLS_YTITLE
                    }
                },
                legend: {
                    enabled: false
                }, 
                series: series,
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
                tooltip: {
                    headerFormat: "<div class='hTooltipHeader'>{point.key}</div><br>",
                    pointFormatter: function() {
                        let x = Utils.round(this.y,2);
                        return "<div class=hTooltipBody'>"+this.series.name+': <b>'+Utils.round(this.y,2).toLocaleString('el-GR')+" "+STRINGS.CHARTS.FUNDS.COLS_YTITLE+"</b></div>";
                    },
                    useHTML: true
                },
                borderRadius: 5
        });
      }
}